let config = {
  // Set the Demo Version
  
  apiUrl: "https://tusmd.com/admin/api/",
  apiAccessKey: 8525,
  
  // you get this type data from google adsense

  // <ins class="adsbygoogle"
  //    style="display:block"
  //    data-ad-client="xxxxxxxxxxxx"
  //    data-ad-slot="xxxxxxxxxxxxxx"
  //    data-ad-format="auto"
  //    data-full-width-responsive="true"></ins>

  // googleAddsense
  data_ad_client: "ca-pub-9667891148162497", //ca-pub-your-ad-client-id
  data_ad_slot:"9154149295", //your-ad-slot-id
  
  //SEO Configurations
  metaDescription: "Tusmd is a Web Quiz Application",
  metaKeyWords: "Tusmd,Quiz,Questions,Answers,Online Quiz",
  
  //Language Configurations
  // Get Your Language Codes ---> https://developers.google.com/admin-sdk/directory/v1/languages
  supportedLanguages: ["en", "hi", "tr"],
  defaultLanguage: "tr",

  // If your Default Language is not in supportedLanguages then add there first and after that set the defaultLanguage.

  //Quiz Configurations
  deductReviewAnswerCoins: 10, // 10 coins will be deducted if user Review the Answer
  deductIncorrectAnswerScore: 1, // This will deduct the points if user will give wrong answer
  deductLifeLineCoins: 1, // Deduct Coins when using Life Line

  // default country selection Configurations
  DefaultCountrySelectedInMobile: "in", //Default Country Selected in Mobile Login Screen

  // guess the word Quiz Configurations
  Guessthewordhintcoin: 5, //deduct coins guess the word

  // 1 vs 1 battle Quiz Configurations
  // matchrandomBattleSeconds: 30,
  battlecorrectanswer: 4,
  randomBattleSeconds:30, // 1 vs 1 battle timer seconds
  Randombattlecoins: 5, //deduct coins 1 vs 1 battle
  randomBattleoneToTwoSeconds: 2, // quick answer :- 1 vs 1 battle points 2 added on first 1 & 2 seconds
  randomBattlethreeToFourSeconds: 1, // quick answer :- 1 vs 1 battle points 1 added on first 3 & 4 seconds


  // audio question timer
  audiotimer: 30,
  mathtimer:30,

  //Firebase Configurations
  apiKey: "AIzaSyBbuhHCZxwReSz1Wku-hZgs0A_j6NY72ho",
  authDomain: "tusmd-5708b.firebaseapp.com",
  projectId: "tusmd-5708b",
  storageBucket: "tusmd-5708b.appspot.com",
  messagingSenderId: "768559126009",
  appId: "1:768559126009:web:c3b7472dc597d3ef2f9928",
  measurementId: "G-GKEV697TVS",
  //footer area
  companytext: "Tusmd bir quiz programı olmakla birlikte aynı zamanda yarışma ve kendini tus alanınında geliştirme uygulamasıdır.",
  addresstext: "Adres:Bağlaraltı mah. 5.cadde no:11 Yıldırım/Bursa",
  phonenumber: "000000000000",
  email: "tusmdyeni@gmail.com",
  facebooklink: "https://www.facebook.com/wrteam.in",
  instagramlink: "https://www.instagram.com/drofkartal",
  companyname: "tusmd",
};

export default config;
